.header img {
  width: 35px;
  display: inline-block;
}

.content {
  background: #F0F2F5!important;
  padding: 14px;
}

.footer {
  background-color: #fff!important;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .content  {
    padding: 10px;
  }
}