.login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__wrapper {
  width: 90%;
  height: 90vh;
  border: 1px solid #ccc;
  display: flex;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.left__content,
.right__content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content__wrapper {
  width: 60%;
}

.content__wrapper-title {
  font-family: "Ubuntu", sans-serif;
  font-size: 2.5em;
  margin-bottom: 40px;
}
.content__wrapper-title::after {
  content: "";
  display: block;
  width: 10%;
  height: 4px;
  background-color: #fff;
  border-radius: 2px;
}

.content__wrapper-description {
  font-size: 1.05em;
  color: #eee;
  line-height: 23px;
}

.left__content {
  background: #007cef;
}
.left__content .content__wrapper,
.left__content .content__wrapper-title {
  color: #fff;
}

.right__content {
  background: #fff;
  text-align: center;
}
.right__content .content__wrapper-title::after {
  background-color: #007cef;
  margin: auto;
}

.content__wrapper-form label {
  font-size: 1.1em !important;
  font-weight: 600;
}

.border-only-bottom input,
.border-only-bottom .ant-input-password,
.border-only-bottom input:hover,
.border-only-bottom input:active,
.border-only-bottom input:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
  box-shadow: none !important;
}

.content__wrapper-form button {
  display: block;
  width: 50% !important;
  height: 40px;
  margin: 20px auto;
  font-size: 1.2em;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3) !important;
}
.content__wrapper-form button:first-of-type {
  margin-top: 30px;
}

@media screen and (max-width: 1400px) {
  .content__wrapper {
    width: 70%;
  }

  .content__wrapper-form button {
    width: 60% !important;
  }
}

@media screen and (max-width: 1300px) {
  .content__wrapper-title {
    font-size: 2.2em;
  }

  .content__wrapper-title::after {
    width: 20%;
  }

  .content__wrapper-description,
  .content__wrapper-form label {
    font-size: 1em !important;
  }

  .content__wrapper-form button {
    font-weight: 400;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 1000px) {
  .content__wrapper {
    width: 80%;
  }

  .content__wrapper-title {
    font-size: 2em;
  }

  .content__wrapper-title {
    margin-bottom: 25px;
  }

  .content__wrapper-description,
  .content__wrapper-form label {
    font-size: 0.95em !important;
  }
}

@media screen and (max-width: 900px) {
  .content__wrapper-title::after {
    width: 15%;
  }

  .content__wrapper-form button {
    width: 70% !important;
  }
}

@media screen and (max-width: 700px) {
  .left__content {
    display: none;
  }

  .content__wrapper {
    width: 70%;
  }

  .content__wrapper-form button {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 468px) {
  .content__wrapper {
    width: 80%;
  }

  .content__wrapper-title {
    font-size: 1.9em;
  }

  .content__wrapper-form button {
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .content__wrapper-title::after {
    width: 22%;
  }
}
