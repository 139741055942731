.App {
  font-family: "Source Sans Pro", sans-serif;
}

/* login start */
.input-email-login,
.input-password-login {
  background-color: #fff !important;
}

.input-submit-login {
  color: #efefef !important;
  height: 50px !important;
  margin: 10px 0;
  transition: 0.3s;
  border: none !important;
}

.login-input {
  border: none !important;
  background-color: #fff !important;
  padding: 10px !important;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
}
/* login end */

/* dashboard start */
.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.table-header .includes-export {
  display: flex;
  align-items: center;
}
.table-header .includes-export button:first-child,
.table-header .includes-export button:nth-child(2) {
  margin-right: 10px;
}
.table-header .includes-export button:last-child {
  color: green;
}
.excel-btn {
  width: 32px;
  cursor: pointer;
  /* filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.5)); */
}
.table-header h1 {
  font-size: 22px;
  font-weight: 600;
}

.search-wrapper .ant-input-affix-wrapper,
.search-wrapper div.ant-picker {
  padding: 7px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1.5px solid #ddd;
}

.search-wrapper.filter-wrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
/* dashboard end */

/* universal start */
.form-control {
  margin-bottom: 15px;
}

.form-control label {
  font-weight: bold;
}

.form-control input,
form button,
.form-control select {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 5px;
  border-radius: 2px;
  color: #666;
}
.form-control input,
.form-control select {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.form-control input:focus,
.form-control select:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
  outline: 0;
}

form button {
  background-color: #1890ff;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

form button:hover {
  background-color: #4da6f9;
}

.delete-title {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
}

.auth-form {
  height: 0;
  overflow: hidden;
  transition: 0.5s;
}
.auth-form button {
  width: 45%;
  margin-right: 5px;
}

.auth-form.active {
  height: 250px;
}

.card-component {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.card-component.in {
  /* background-color: rgb(15, 204, 138); */
  background-color: #35daa8;
}
.card-component.out {
  /* background-color: rgb(243, 160, 6); */
  background-color: #ffba52;
}

.card-component div:first-child {
  width: 70%;
}
.card-component div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
}
.card-component div:first-child h3 {
  font-weight: 700;
}
.card-component div:last-child img {
  width: 60%;
}

.select-presence {
  display: none;
}
.select-presence.activ {
  display: block;
}
.side-auth {
  display: none;
}
.side-auth.activ {
  display: block;
  width: 60%;
}

.login-btn .ant-form-item-control-input-content {
  margin-top: 100px;
  display: flex;
  gap: 10px;
}
.ant-layout-sider-trigger {
  background: #002e5a;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #18978f !important;
  border-color: #18978f !important;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff !important;
}
.ant-radio-button-wrapper:hover {
  color: #18978f !important;
}
.schedule {
  display: none !important;
}
.schedule.active {
  display: flex !important;
}

.btn-orange {
  background: orangered !important;
  color: #fff !important;
  border: none !important;
}

.ant-breadcrumb li:last-child a {
  color: rgba(0, 0, 0, 0.45) !important;
}
.ant-breadcrumb li:last-child a:hover {
  color: black !important;
}

/* universal end */
@media screen and (max-width: 1400px) {
  .card-component {
    font-size: 1em;
  }
  .card-component div:first-child h3 {
    font-size: inherit;
  }

  .card-component div:last-child {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 1000px) {
  .card-component div:last-child {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 600px) {
  .table-header h1 {
    font-size: 16px;
  }
  .auth-form label {
    font-size: 0.9em !important;
  }
  .auth-form button {
    width: 100%;
    font-size: 0.9em;
  }
  .card-component {
    font-size: 0.9em;
    padding: 10px;
    gap: 5px;
    align-items: center;
  }
  .card-component div:last-child {
    align-items: center;
  }
  .card-component div:first-child h3 {
    font-size: 1em !important;
  }
  .card-component div:last-child img {
    width: 70%;
  }
  .side-auth {
    width: 95% !important;
    position: absolute;
    height: 50px;
    bottom: 25px;
    left: 10px;
    background: #fff;
  }
  .btn-orange {
    margin-bottom: 5px;
  }
}
