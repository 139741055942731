body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #fff;
  z-index: 9999999999;
}

.ant-layout-sider-trigger {
  top: 0;
  height: 48px;
  background: rgba(255, 255, 255, 0.3) !important;
}

.logo {
  height: 60px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-divider-inner-text {
  font-size: 19px;
}

/* ant table start */

.ant-table {
  font-size: 1.1em !important;
  border-radius: 5px !important;
  overflow: auto;
  border-top: none;
  border-bottom: none;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
}
th.ant-table-cell {
  background-color: #256ad1 !important;
  color: #fff !important;
  padding: 10px !important;
}

td.ant-table-cell {
  border: none !important;
  border-bottom: 1px solid #eee !important;
  transition: 0.3s;
}
.ant-table tr:nth-child(even) {
  background-color: #eee;
}
.ant-table tr:nth-child(odd) {
  background-color: #fff;
}
.ant-table-row:hover td {
  background: #ddd !important;
  transition: 0.3s;
}
th.ant-table-cell,
td.ant-table-cell {
  max-width: 170px;
}

.ant-btn.ant-btn-round.ant-btn-primary.ant-btn-sm {
  margin-right: 5px;
  margin-bottom: 3px;
  background-color: #18978f;
  border: none;
}

/* ant table end */

/* ant form start  */
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 3px !important;
}

/* ant form end  */

/* ant Description table start */
.recap-description .ant-descriptions-view {
  border-radius: 10px;
}
tr.ant-descriptions-row:nth-child(odd) {
  background-color: #eee !important;
}
tr.ant-descriptions-row th {
  background-color: #fff !important;
}
tr.ant-descriptions-row:nth-child(odd) th {
  background-color: #eee !important;
}
div.ant-descriptions-view table {
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px !important;
}

.activity-description div.ant-descriptions-view table {
  box-shadow: none;
}

/* ant Description table end */

/* ant form start */
div.ant-picker {
  width: 100%;
}

span.ant-divider-inner-text {
  font-size: 1.7rem;
}

/* ant form end */

@media screen and (max-width: 900px) {
  .ant-table {
    font-size: 1em !important;
  }
}
